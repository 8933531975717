@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

$borderStyle: 1px solid rgba(250, 250, 250, 0.7);
$padding: 10px 15px;

.wrap.sectionSubnav {
    overflow: visible;
    position: relative;
    z-index: 10;

    @include breakpoint(sm) {
        padding: 0;
    }
}

.sectionSubnav__mobileWrap {
    align-items: center;
    display: flex;
    font-weight: bold;
    justify-content: center;
    position: relative;
    padding: 15px 20px;
    @include breakpoint(lg) {
        padding: $padding;
    }
}

.sectionSubnav__mobileWrap::after {
    @include breakpoint(lg) {
        left: 100%;
        top: 0%;
        border: solid transparent;
        content: " ";
        height: 100%;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        clip-path: polygon(0 0, 0% 100%, 30% 50%);
        border-width: 20px;
        margin-top: 0px;
        margin-left: -1px;
        z-index: 2;
    }
}

.sectionSubnav__mobileButton {
    display: block;
    @include breakpoint(lg) {
        display: none;
    }
}

.sectionSubnav__wrap {
    display: flex;
    flex-direction: column;
    position: relative;

    @include breakpoint(lg) {
        flex-direction: row;
    }
}

.sectionSubnav__topMenuList {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;

    li {
        height: 100%;
    }

    .is-open & {
        display: flex;
    }

    @include breakpoint(lg) {
        display: flex;
        flex-direction: row;
        position: relative;
        top: auto;
        right: auto;
        left: auto;
    }
}

.sectionSubnav__subNav {
    position: relative;
    border-top: $borderStyle;
    height: 100%;

    @include breakpoint(lg) {
        border-top: 0;
    }
}

.sectionSubnav__menuWrap {
    position: relative;
    height: 100%;
}

.sectionSubnav__menuToggler {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 80px;
    background: transparent;
    border: none;
    outline: none;
    pointer-events: none;

    @include breakpoint(lg) {
        display: none;
    }
}

.sectionSubnav__menuToggler > svg,
.sectionSubnav__menuToggler > img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    fill: $color-white;
}

.sectionSubnav__menuToggle-is-open {
    display: none;

    .is-open > & {
        display: block;
    }
}

.sectionSubnav__menuToggle-is-closed {
    display: block;

    .is-open > & {
        display: none;
    }
}

ul.sectionSubnav__subMenu {
    display: none;
    flex-direction: column;
    min-width: 320px;
    padding: 0;
    border-bottom: $borderStyle;

    .is-open > & {
        display: flex;
    }

    @include breakpoint(lg) {
        left: 0;
        padding: 0;
        position: absolute;
        top: 100%;

        .sectionSubnav__subNav:hover & {
            display: flex;
        }
    }
}

.sectionSubnav__subMenu > li {
    @include breakpoint(lg) {
        border-top: $borderStyle;
    }

    &:first-child {
        border-top: 0;
    }
}

.sectionSubnav__subNavLink--hasChildren {
    .sectionSubnav__subNavLinkItem {
        padding: 8px 20px;
        flex: 1;
    }

    .sectionSubnav__grandChildrenSubMenu {
        display: none;

        li {
            @include breakpoint(lg) {
                border-top: $borderStyle;
            }
        }

        .sectionSubnav__subNavLinkItem {
            padding-left: 2rem;
        }
    }

    .sectionSubnav__subNavLinkItemContainer {
        display: flex;
        justify-content: space-between;

        img {
            width: 1rem;
            height: 1rem;
            padding: 0;
            margin-right: 20px;
            margin-top: 10px;
            cursor: pointer;

            &.sectionSubnav__subNavLinkIcon {
                margin: 0;
            }
        }
    }

    .sectionSubnav__subNavLinkIcon {
        &--closed {
            display: none;
        }

        &--open {
            display: block;
        }
    }

    &.is-open {
        .sectionSubnav__subNavLinkIcon {
            &--closed {
                display: block;
            }

            &--open {
                display: none;
            }
        }

        .sectionSubnav__grandChildrenSubMenu {
            display: block;
        }
    }
}

.sectionSubnav a {
    text-decoration: none;
}

.sectionSubnav__subMenu a,
.sectionSubnav .sectionSubnav__menuLink {
    background: transparent;
    border-width: 0;
    display: block;
    font-size: 0.9rem;
    font-weight: bold;
    position: relative;
    text-decoration: none;
    transition: color 0.25s ease-out;
    padding: 15px 20px;
    height: 100%;
    color: $color-white;

    @include breakpoint(lg) {
        padding: 12px 20px;
    }
}
.sectionSubnav__topMenuList > :first-child .sectionSubnav__menuLink {
    @include breakpoint(lg) {
        padding-left: 30px;
    }
}

@include forColors(
        $optOut: (
            "orange"
        )
    )
    using ($color, $value) {
    .colorTheme--#{$color} {
        .sectionSubnav__topMenuList,
        .sectionSubnav__wrap {
            background: $value;
        }

        .sectionSubnav__mobileWrap,
        .sectionSubnav__subMenu {
            background: darken($value, 10%);
        }
        .sectionSubnav__mobileWrap:after {
            border-left-color: darken($value, 10%);
        }

        @if isColorLight($value) {
            .sectionSubnav__mobileWrap,
            .sectionSubnav a {
                color: $color-black;
            }

            .sectionSubnav__subNav:hover .sectionSubnav__menuLink,
            .sectionSubnav__subMenu a:hover {
                background: rgba($color-black, 0.1);
            }

            .navLink__title--active span,
            .navLink__title--active a {
                background: rgba($color-black, 0.2);
            }
        }
        @if isColorDark($value) {
            .sectionSubnav__mobileWrap,
            .sectionSubnav a {
                color: $color-white;
            }

            .sectionSubnav__subNav:hover .sectionSubnav__menuLink,
            .sectionSubnav__subMenu .sectionSubnav__subNavLinkItem:hover {
                background: rgba($color-white, 0.1);
            }
            .sectionSubnav__subMenu .sectionSubnav__subNavLinkItemContainer:hover {
                background: rgba($color-white, 0.1);

                .sectionSubnav__subNavLinkItem {
                    background: transparent;
                }
            }
            .sectionSubnav__subNavLink--hasChildren {
                .sectionSubnav__grandChildrenSubMenu .sectionSubnav__subNavLinkItemContainer {
                    background: rgba($color-white, 0.1);

                    &:hover {
                        background: rgba($color-white, 0.15);
                    }
                }
            }

            .navLink__title--active a,
            .navLink__title--active span {
                background: rgba($color-white, 0.2);
            }
        }
    }
}

.magnolia-editmode {
    .sectionSubnav__mobileWrap::after {
        display: none;
    }
}

.sectionSubnav__subNavLinkItemBtn {
    background: transparent;
    border: none;
    outline: none;
    padding: 10px 20px;
}


/*SUBNAV*/
.casestudy .sectionSubnav.wrap{max-width: 100%;}
.casestudy .sectionSubnav__wrap{max-width: 1440px; margin: 0 auto; padding: 0 2rem;}
/*Colores*/
/*DEFAULT dark blue*/
.sectionSubnav.wrap.darkblue, .sectionSubnav.wrap.darkblue .sectionSubnav__wrap, .sectionSubnav.wrap.darkblue .sectionSubnav__topMenuList{background:#003865 !important;}
.sectionSubnav.wrap.darkblue .sectionSubnav__mobileWrap{background-color:#001c32 !important}
.sectionSubnav.wrap.darkblue .sectionSubnav__mobileWrap:after{border-left-color:#001c32 !important}

/*light green*/
.sectionSubnav.wrap.lightgreen, .sectionSubnav.wrap.lightgreen .sectionSubnav__wrap, .sectionSubnav.wrap.lightgreen .sectionSubnav__topMenuList{background:#7fba00 !important;}
.sectionSubnav.wrap.lightgreen .sectionSubnav__mobileWrap{background-color:#5c8700 !important}
.sectionSubnav.wrap.lightgreen .sectionSubnav__mobileWrap:after{border-left-color:#5c8700 !important}

/*light gray*/
.sectionSubnav.wrap.lightgray, .sectionSubnav.wrap.lightgray .sectionSubnav__wrap, .sectionSubnav.wrap.lightgray .sectionSubnav__topMenuList{background:#60605b !important;}
.sectionSubnav.wrap.lightgray .sectionSubnav__mobileWrap{background-color:#464642 !important}
.sectionSubnav.wrap.lightgray .sectionSubnav__mobileWrap:after{border-left-color:#464642 !important}