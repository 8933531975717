@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

.articleHeadline {
    background-color: $page-background;
    padding: 3rem 2rem;
    position: relative;

    * {
        position: relative;
        text-align: center;
    }

    &--tinted {
        color: $color-white;

        &:before {
            content: "";
            position: absolute;
            background-color: rgba(104, 108, 110,1);
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }

        .articleHeadline__textContainer .articleHeadline__title, .articleHeadline__textContainer .articleHeadline__subtitle {
            color: $color-white;
        }
    }

    @media screen and (min-width: $width-desktop-min) {
        padding: 70px;
    }
}

.articleHeadline__textContainer {
    .articleHeadline__title {
        color: $color-black;
        font-weight: 400;
        margin-bottom: 0;
        font-size: 1.1rem;

        &.articleHeadline--textWhite {
            color: $color-white;
            border-color: $color-white;
        }

        @include breakpoint(md) {
            font-size: 2rem;
        }
    }

    .articleHeadline__subtitle {
        color: $color-black;
        font-size: 16px;
        margin-top: map-get($spacing, 2);
        font-weight: 300;
        line-height: 1.3;

        @include breakpoint(md) {
            font-size: 20px;
        }

        &.articleHeadline--textWhite {
            color: $color-white;
        }
    }
}

.articleHeadline__border {
    border: $color-grey-70;
    display: block;
    height: 5px;
    margin: 25px auto;
    width: 250px;
}

.articleMain {
    .sidebarPanel {
        background: transparent;

        > * {
            margin: 1rem 0 2.5rem 0; /* keep consistent with pages that use sidebarPanel as inner component vs entire sidebar */

            &:first-child {
                margin-top: 0;
            }

            .sidebarSection__innerWrap,
            .pardotForm__innerWrap {
                margin: -20px; /* offsets padding value of parent */
                padding: 20px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
                /* transparent gradient applied to inner wrap for increased flexibility wrt page theme */
            }
        }

        .sidebarSection {
            background: rgb(85, 118, 152);
        }

        .author {
            /* a discrete authorList component would be preferred, but this approach addresses preexisting content */
            /* first-child pseudoclass presumes authors will always be listed at top of sidebar */
            margin-top: -2.5rem;
            margin-bottom: 2.5rem;
            padding-top: 0;

            &:first-child {
                margin-top: 0;
                padding-top: 20px;
            }
        }

        .author--white {
            background: #fff;
        }
    }
}

.articleMain__content {
    .articleHeadline {
        margin-top: 20px;
    }
}

@include forColors() using ($color, $value) {
    .colorTheme--#{$color} .articleHeadline,
    .articleHeadline--#{$color} {
        border-top: 15px solid $value;

        .articleHeadline__border {
            background-color: $value;
        }

        .articleHeadline__anchorButton a {
            color: $value;
            &:hover {
                color: $color-white;
            }
        }
    }
}
