@use "../base";
@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

@use "../modules/articleHeadline";
@use "../modules/boxedTexts";
@use "../modules/colorDivider";
@use "../modules/colorTint";
@use "../modules/columns";
@use "../modules/cta";
@use "../modules/ctaBand";
@use "../modules/ctaBar";
@use "../modules/ctaList";
@use "../modules/image";
@use "../modules/page";
@use "../modules/pageHero";
@use "../modules/richText";
@use "../modules/share";
@use "../modules/sectionNav";
@use "../modules/_sectionNavV2";
@use "../modules/theme";
@use "../modules/title";
@use "../modules/video";
@use "../modules/titleHr";
@use "../modules/parallaxedSection";
@use "../modules/quote";

$color-jabil-dark-blue: $color-jabil-darker-blue;

.sustainabilityPage {

    .ctaList .cta__content {
        padding: 0;
    }

    .pageHero {
        display: flex;
        align-items: flex-end;

        .titleHr:after {
            width: 50%;
        }
    }

    .pageHero--tall {
        .pageHero {
            @media screen and (min-width: $width-standard-screen-min) {
                min-height: 50em;
            }
        }
    }

    .columnsContainer {
        .columns__title {
            display: inline-block;
            line-height: 1;
            font-size: 1.5rem;
            font-weight: 500;
            padding-bottom: 1.5rem;
            margin: 0 0 2rem;

            @include breakpoint(md) {
                font-size: 2rem;
                padding-bottom: 1.75rem;
                margin-bottom: 1.75rem;
            }

            @include breakpoint(md-lg) {
                font-size: 2.5rem;
            }

            @include breakpoint(lg) {
                padding-bottom: 2rem;
                margin-bottom: 2rem;
            }

            &:after {
                width: 50%;
                border-bottom-width: 3px;
            }
        }

        .columns__column {
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: unset;
            }
        }

        .wrap {
            padding: 7% 2rem;
            max-width: $width-content-wrap;
        }

        .richText {
            * {
                color: $color-white;
            }

            h3 {
                padding-top: 0;
                padding-bottom: 0;
                font-size: 1.25rem;
                margin-bottom: 15px;
            }

            td h3 {
                margin-bottom: 0;
            }

            p {
                font-size: 1.125rem;
                font-weight: 300;
                line-height: 1.5;
            }

            ul {
                list-style-type: none;
                padding-left: 0;

                li {
                    font-size: 1.125rem;
                    font-weight: 300;
                    line-height: 1.5;
                    padding-left: 1.5rem;
                    background-image: url("../../src/img/icons/chevron-green-sm.svg");
                    background-position: 0 0.5rem;
                    background-size: 1rem 1rem;
                    background-repeat: no-repeat;
                }
            }

            table {
                margin-bottom: 1.25rem;
            }

            @include breakpoint(md) {
                h3 {
                    font-size: 1.5rem;
                    margin-bottom: 20px;
                }

                p,
                ul li {
                    font-size: 1.25rem;
                }
            }
        }

        .image {
            margin-top: 1.25rem;
            margin-bottom: 1.25rem;
        }

        .image__caption {
            color: $color-white;
            font-size: 1rem;
            font-weight: 300;
            line-height: 1.5;
            text-align: center;
            margin-top: 12px;
        }

        @include breakpoint(md) {
            .columns__column {
                margin-bottom: unset;
            }

            .image {
                margin-top: 0.5rem;
            }

            .image__caption {
                font-size: 1.125rem;
            }
        }

        .columns--3-up {
            @include breakpoint(md) {
                .columns__column {
                    margin-bottom: 1rem;

                    &:last-child {
                        margin-bottom: unset;
                    }
                }

                .image {
                    margin-top: 1.25rem;
                }
            }

            @media screen and (min-width: $width-medium-tablet-min) {
                .columns__column {
                    margin-bottom: unset;
                }

                .image {
                    margin-top: 0.5rem;
                }
            }
        }

        .columns--3-up.columns--reducedBreakpoint {
            @include breakpoint(md) {
                .columns__column {
                    margin-bottom: unset;
                }

                .image {
                    margin-top: 0.5rem;
                }
            }
        }
    }

    .columnsContainer--dark-blue {
        background-color: $color-jabil-darker-blue;
    }

    .parallaxedSection {
        .video,
        .video__subtitle {
            margin-bottom: 0;
        }
    }

    .parallaxedSection__heading {
        position: relative;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;

        &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 50%;
            content: " ";
            border-bottom-width: 3px;
            border-bottom-style: solid;
        }

        @include breakpoint(md) {
            padding-bottom: 1.75rem;
            margin-bottom: 1.75rem;
        }

        @include breakpoint(lg) {
            padding-bottom: 2rem;
            margin-bottom: 2rem;
        }

        @include breakpoint(xl) {
            display: none;
        }

        &--mobile {
            display: inline-block;

            @include breakpoint(xl) {
                display: inline-block;
            }
        }
    }

    .pageHero__description {
        margin-bottom: 18px;
    }

    .pageHero__linkList {
        margin-bottom: 0;
    }

    .pageHero__buttons {
        padding-top: 1.5rem;
    }

    .sectionSubnav__wrap {
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 2rem;
    }
}

@include forColors() using ($color, $value) {
    .sustainabilityPage.colorTheme--#{$color} {
        .parallaxedSection__heading:after {
            border-bottom-color: $value;
        }


    }
}

.wrap.sectionSubnav {
    max-width: 100% !important;
    margin: 0 auto;
    padding: 0 2rem;
}

.ctaList {

  .ctaList--3-up {

    > li {
      border: none;
      background: none;
    }

    .ctaList .cta__content {
      padding: 0;
    }

  }

}
