@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

.quote {
    font-family: $font-times-new-roman;
    margin: 2em 0;
}

.quote--hasAuthorImage .quote__details {
    margin-right: 1em;
}

.quote__wrap {
    padding-left: 3em;
    width: 100%;

    @media screen and (min-width: $width-tablet-min) {
        display: flex;
        align-items: center;
    }
}

.quote__title {
    color: $color-jabil-blue;
    font-style: italic;
    font-size: 1.5em;
    margin: 0;
}

.quote__text {
    font-family: $font-lato;
    color: $color-jabil-blue;
    font-style: italic;
}

.quote__author {
    font-family: $font-lato;
    font-style: italic;
    margin-top: 1em;
    font-size: 1.3rem;
    text-align: right;
    color: white;
}

.quote__image {
    max-width: 150px;
    width: 100%;
    object-fit: cover;
}

@include forColors() using ($color, $value) {
    .colorTheme--#{$color} .quote__author {
        color: $value;
    }
}
