@use "../mixins/color" as *;

.colorDivider {
    height: 15px;
}

@include forColors() using ($color, $value) {
    .colorDivider--#{$color} {
        background-color: $value;
    }

    .colorTheme--#{$color} {
        .colorDivider--default {
            background-color: $value;
        }
    }
}
